import { Link } from '@remix-run/react'
import React from 'react'
import SubMenu from '#app/components/navigation/nav-item/submenu.tsx'
import { cn } from '#app/utils/misc.ts'
import {
	type CategoryStoryblok,
	type NavigationCategoriesStoryblok,
	type ParentCategoryStoryblok,
} from '#types/component-types-sb'
import ButtonAndLinks from '../typography/button-and-links'

const HeaderKeysRow = ({
	keys,
	categoriesToShow,
	brandsToShow,
}: {
	keys: {
		link: string
		name: string
		isSale?: boolean
		categories?: (CategoryStoryblok | ParentCategoryStoryblok)[]
	}[]
	categoriesToShow: NavigationCategoriesStoryblok | undefined
	brandsToShow: (CategoryStoryblok | ParentCategoryStoryblok) | undefined
}) => {
	const [hoveredKey, setHoveredKey] = React.useState<string | null>(null)
	return (
		<div className="group">
			<div className="peer relative flex gap-6">
				{keys.map(({ link, name, isSale, categories }) => {
					return (
						<HeaderKey
							key={link}
							link={link}
							name={name}
							isSale={isSale}
							onMouseEnter={() => (categories ? setHoveredKey(name) : null)}
							isActive={hoveredKey === name}
						/>
					)
				})}
			</div>
			{categoriesToShow && hoveredKey && (
				<SubMenu
					content={categoriesToShow.categories?.find(
						key => key.title === hoveredKey,
					)}
					brands={brandsToShow}
				/>
			)}
		</div>
	)
}

const HeaderKey = ({
	link,
	name,
	isSale,
	onMouseEnter,
	isActive,
}: {
	link: string
	name: string
	isSale?: boolean
	onMouseEnter: () => void
	isActive?: boolean
}) => {
	return (
		<Link
			to={link}
			onMouseEnter={onMouseEnter}
			className="relative inline-block"
		>
			<ButtonAndLinks
				as="xs"
				body={name}
				className={cn(
					`border-b border-b-transparent hover:border-black-90 focus:border-mint-green-50 ${
						isSale ? 'text-red-90' : ''
					}`,
					isActive ? 'border-black-90' : '',
				)}
			/>
			{link === '/stores' && (
				<span className="absolute -right-24 -top-2 whitespace-nowrap rounded-full bg-mint-green-50 px-2 py-0.5 text-[10px] text-black-90">
					shop click & collect!
				</span>
			)}
		</Link>
	)
}

export default HeaderKeysRow

export const getWomenCategories = (
	t: (arg0: string) => string,
	onSaleWomen: boolean,
	categories: (CategoryStoryblok | ParentCategoryStoryblok)[] | undefined,
) => [
	...(onSaleWomen
		? [
				{
					link: `/products/women?sortingOrder=on_sale`,
					name: t('header_sale'),
					isSale: true,
				},
		  ]
		: []),
	{ link: `/products/women?sortingOrder=new`, name: t('header_new') },
	{ link: `/products/women?sortingOrder=popular`, name: t('header_popular') },
	...(categories || []).map(it => ({
		link: it.link?.url,
		name: it.title,
		categories: it.categories,
	})),
	{ link: '/brands', name: t('brands_header') },
	{ link: '/stores', name: t('stores_header') },
]

export const getMenCategories = (
	t: (arg0: string) => string,
	onSaleMen: boolean,
	categories: (CategoryStoryblok | ParentCategoryStoryblok)[] | undefined,
) => [
	...(onSaleMen
		? [
				{
					link: `/products/men?sortingOrder=on_sale`,
					name: t('header_sale'),
					isSale: true,
				},
		  ]
		: []),
	{ link: `/products/men?sortingOrder=new`, name: t('header_new') },
	{ link: `/products/men?sortingOrder=popular`, name: t('header_popular') },
	...(categories || []).map(it => ({
		link: it.link?.url,
		name: it.title,
		categories: it.categories,
	})),
	{ link: '/brands', name: t('brands_header') },
	{ link: '/stores', name: t('stores_header') },
]

export const getKidsCategories = (
	t: (arg0: string) => string,
	onSaleKids: boolean,
	categories: (CategoryStoryblok | ParentCategoryStoryblok)[] | undefined,
) => [
	...(onSaleKids
		? [
				{
					link: `/products/kids?sortingOrder=on_sale`,
					name: t('header_sale'),
					isSale: true,
				},
		  ]
		: []),
	{ link: `/products/kids?sortingOrder=new`, name: t('header_new') },
	{ link: `/products/kids?sortingOrder=popular`, name: t('header_popular') },
	...(categories || []).map(it => ({
		link: it.link?.url,
		name: it.title,
		categories: it.categories,
	})),
	{ link: '/brands', name: t('brands_header') },
	{ link: '/stores', name: t('stores_header') },
]
