
import { type IconName } from '@/icon-name'
import { NavItemContainer } from './responsive-nav-link-item'

interface ResponsiveNavItemProps {
	name: string
	iconName?: IconName
	isActive?: boolean
	handleOptionClick?: (option: any) => void
}

export const ResponsiveNavItem = ({
	name,
	iconName,
	handleOptionClick,
	isActive,
}: ResponsiveNavItemProps) => {
	const handleClick = () => {
		if (handleOptionClick) {
			handleOptionClick(name)
		}
	}
	return (
		<div className="cursor-pointer" onClick={handleClick}>
			<NavItemContainer iconName={iconName} name={name} isActive={isActive} />
		</div>
	)
}

