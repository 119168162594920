import { Form, Link, useSubmit } from '@remix-run/react'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import SeparatorLine from '#app/components/common/separator-line.tsx'
import PlentyBody from '#app/components/typography/plenty-body.tsx'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links.tsx'
import { Button } from '#app/components/ui/button.tsx'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '#app/components/ui/dropdown-menu.tsx'
import { Icon } from '#app/components/ui/icon.tsx'
import { trackRegisterNowDropdownClick } from '#app/utils/tracking/amplitude/customer-profile'

export function CustomerDropdownMenu({ userId }: { userId: string | null }) {
	const { t } = useTranslation('customer_portal')
	const submit = useSubmit()

	const formRef = useRef<HTMLFormElement>(null)
	return (
		<DropdownMenu>
			<DropdownMenuTrigger className="focus-visible:outline-none">
				<Icon
					className="text-body-md"
					size="lg"
					name="avatar"
					aria-hidden="true"
				/>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-[375px] rounded-b-md border-t-0 bg-white shadow-bottom md:flex md:flex-col">
				{!userId && (
					<>
						<DropdownMenuItem className="px-4 focus:bg-white" asChild>
							<Link to="/login">
								<Button variant={'primary'} size={'primary'} fullWidth>
									<PlentyButtonsAndLinks as="btn-md">
										{t('customer_portal:login_dropdown')}
									</PlentyButtonsAndLinks>
								</Button>
							</Link>
						</DropdownMenuItem>

						<DropdownMenuItem className="p-4">
							<Link to={'/signup'} onClick={trackRegisterNowDropdownClick}>
								<div className="flex gap-1">
									<PlentyBody
										size="md"
										className="font-bold text-mint-green-60"
									>
										{t('customer_portal:register_now_dropdown')}
									</PlentyBody>
									<PlentyBody size="md">
										{t('customer_portal:only_takes_a_min_dropdown')}
									</PlentyBody>
								</div>
							</Link>
						</DropdownMenuItem>
						<SeparatorLine />
					</>
				)}
				{userId && (
					<DropdownMenuItem className="p-4" asChild>
						<Link to="/customer-portal/overview">
							<PlentyBody size="md">
								{t('customer_portal:orders_dropdown')}
							</PlentyBody>
						</Link>
					</DropdownMenuItem>
				)}
				<DropdownMenuItem className="p-4" asChild>
					<Link to="/returns">
						<PlentyBody size="md">
							{t('customer_portal:return_a_product_dropdown')}
						</PlentyBody>
					</Link>
				</DropdownMenuItem>
				<DropdownMenuItem className="p-4" asChild>
					<Link to="/faq">
						<PlentyBody size="md">
							{t('customer_portal:help_and_contact_dropdown')}
						</PlentyBody>
					</Link>
				</DropdownMenuItem>
				{userId && (
					<>
						<DropdownMenuItem className="p-4" asChild>
							<Link to="/customer-portal/account-settings">
								<PlentyBody size="md">
									{t('customer_portal:account_settings_dropdown')}
								</PlentyBody>
							</Link>
						</DropdownMenuItem>

						<SeparatorLine />
						<Form action="/logout" method="POST" ref={formRef}>
							<DropdownMenuItem className="p-4" asChild>
								<PlentyBody
									onClick={() =>
										submit({}, { method: 'POST', action: '/logout' })
									}
									size="md"
								>
									{t('customer_portal:logout_dropdown')}
								</PlentyBody>
							</DropdownMenuItem>
						</Form>
					</>
				)}
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
