import { Form } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import ClerkSearchInput from './clerk-search-input'

type ClerkSearchBarProps = {
  searchTerm: string
  onSearchChange: (newSearchTerm: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onClick?: () => void
  shouldAutoFocus?: boolean
  closeSearch?: boolean
  closeOverlay?: () => void
  closeDrawer?: () => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void // Add onKeyDown
  useShadow?: boolean // Add useShadow prop
}

export function ClerkSearchBar({
  searchTerm,
  onSearchChange,
  onFocus,
  onBlur,
  onClick,
  shouldAutoFocus = false,
  closeSearch,
  closeOverlay,
  closeDrawer,
  onKeyDown, // Add onKeyDown to props
  useShadow = false, // Default value for useShadow
}: ClerkSearchBarProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    onSearchChange(newValue)
  }
  let { t } = useTranslation('search')

  return (
    <Form
      method="GET"
      className={`top-margin-2 flex w-[100%] flex-wrap items-center justify-center gap-2 rounded-3xl ${useShadow ? 'shadow-small' : ''}`}
    >
      <ClerkSearchInput
        value={searchTerm}
        placeholder={t('search_products_brands_stores')}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        autoFocus={shouldAutoFocus}
        closeSearch={closeSearch}
        onCloseSearch={closeOverlay || closeDrawer}
        onKeyDown={onKeyDown} // Pass onKeyDown to ClerkSearchInput
      />
    </Form>
  )
}
