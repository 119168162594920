import { Link } from '@remix-run/react'
import React from 'react'
import BlackBackgroundColorForImages from '#app/components/black-background-color-for-images.tsx'
import PlentyBody from '#app/components/typography/plenty-body.tsx'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links.tsx'
import { Button } from '#app/components/ui/button.tsx'

interface PromotionCardProps {
	image: string
	heading: string
	subHeading: string
	link: string
	cta: string
}

const PromotionCard: React.FC<PromotionCardProps> = ({
	image,
	heading,
	subHeading,
	link,
	cta,
}) => {
	return (
		<div className="group/promo relative cursor-pointer">
			<div className="relative z-[-10] mt-6 overflow-hidden rounded-lg transition-transform group-hover/promo:scale-105">
				<img
					loading="lazy"
					decoding="async"
					fetchpriority="low"
					src={image}
					alt={heading}
					className="aspect-auto h-64 w-full object-cover"
				/>
				<BlackBackgroundColorForImages />
				<div className="absolute left-0 top-0 z-10 h-full w-full rounded-2xl bg-gradient-to-t from-black-90 to-transparent opacity-30" />
			</div>
			<div className="absolute bottom-0 left-0 right-0 p-4">
				<PlentyBody size="xs" className="text-white">
					{subHeading}
				</PlentyBody>
				<PlentyButtonsAndLinks as="nav-md" className="text-white">
					{heading}
				</PlentyButtonsAndLinks>
				<Link to={link}>
					<Button
						variant="secondary"
						className="mt-1 rounded-full border-none px-8 py-4 focus:text-black-90"
					>
						{cta}
					</Button>
				</Link>
			</div>
		</div>
	)
}

export default PromotionCard
