import { Drawer, DrawerContent } from '#app/components/ui/drawer'
import SearchOverlay from './search-overlay'

const MobileSearchDrawer = ({ open, setOpen, lastVisitedLanding }: any) => {
	// Accept props for control

	const handleCloseClick = () => {
		setOpen(false) // Use the passed setOpen function
	}
	return (
		<Drawer open={open} direction="right" onOpenChange={setOpen}>
			<DrawerContent className="fixed inset-0 bottom-0 right-0 z-50 mt-0 flex h-full w-full flex-col gap-8 overflow-y-auto overflow-x-hidden rounded-t-none bg-white">
				<SearchOverlay
					closeDrawer={handleCloseClick}
					closeOverlay={handleCloseClick}
					lastVisitedLanding={lastVisitedLanding}
				/>
			</DrawerContent>
		</Drawer>
	)
}

export default MobileSearchDrawer
