import { Skeleton } from '#app/components/ui/skeleton'

const ProductCardSkeleton = () => {
	return (
		<div className="bg-red-500 flex max-h-[408px] w-full max-w-[306px] flex-col items-center justify-center rounded-2xl border p-4 shadow">
			<Skeleton className="h-40 w-full rounded-md" />
			<div className="mt-4 w-full space-y-2">
				<Skeleton className="h-4 w-full rounded" />
				<Skeleton className="h-4 w-5/6 rounded" />
				<div className="flex items-center justify-between">
					<Skeleton className="h-4 w-1/2 rounded" />
				</div>
			</div>
		</div>
	)
}

export default ProductCardSkeleton
