import { Link } from "@remix-run/react"
import SeparatorLine from "#app/components/common/separator-line"
import PlentyHeading from "#app/components/typography/plenty-heading"
import { Icon, type IconName } from "#app/components/ui/icon"



interface ResponsiveNavLinkItemProps {
	name: string
	iconName?: IconName
	link: string
	onTrackEvent?: () => void
}

interface NavItemContainerProps {
	iconName?: IconName
	name: string
	isActive?: boolean
}

export const ResponsiveNavLinkItem = ({
	name,
	iconName,
	link,
	onTrackEvent
}: ResponsiveNavLinkItemProps) => {
	return (
		<Link className="cursor-pointer" to={link} onClick={() => onTrackEvent && onTrackEvent()}>
			<NavItemContainer iconName={iconName} name={name} />
		</Link>
	)
}

export const NavItemContainer = ({
	iconName,
	name,
	isActive,
}: NavItemContainerProps) => {
	return (
		<div className="my-4 flex flex-col">
			<div className=" mb-6 flex w-full flex-row sm-devices:mb-3">
				{!!iconName && <Icon name={iconName} size="lg" className="mr-4" />}
				<div className="flex flex-grow">
					<PlentyHeading className={isActive ? 'font-semibold' : ''} as={'h5'}>
						{name}
					</PlentyHeading>
				</div>

				{!iconName && <Icon name="chevron-right-arrow" size="lg" />}
				{isActive && <Icon name="check-circle" size="lg" />}
			</div>
			<SeparatorLine />
		</div>
	)
}

