import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'

const SearchGenderFilter = ({ activeFilter, onFilterChange }: any) => {
	let { t } = useTranslation('search')
	const filters = [
		{ id: 'all', name: t('search_all'), icon: 'four-squars' },
		{ id: 'men', name: t('navbar:men_header'), icon: 'four-squars' },
		{ id: 'women', name: t('navbar:women_header'), icon: 'four-squars' },
		{ id: 'kids', name: t('navbar:kids_header'), icon: 'four-squars' },
	]

	return (
		<div className="flex cursor-pointer flex-col gap-2">
			<PlentyBody size="md" className="text-beige-100">
				{t('search_in')}
			</PlentyBody>
			<div className="flex flex-wrap gap-2">
				{filters.map(filter => (
					<div
						key={filter.id}
						className={`inline-flex items-center gap-2 rounded-md border border-beige-70 px-2 py-1 hover:bg-beige-50 ${
							activeFilter === filter.id ? 'bg-beige-50' : 'bg-white'
						}`}
						onClick={() => onFilterChange(filter.id)}
					>
						<PlentyBody size="sm">{filter.name}</PlentyBody>
					</div>
				))}
			</div>
		</div>
	)
}

export default SearchGenderFilter
