import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'

const NoResultFound = ({ searchTerm }: any) => {
	let { t } = useTranslation('search')
	return (
		<div className="flex flex-col gap-6">
			<div className="flex flex-col gap-2">
				<PlentyBody size="md" className="text-beige-100">
					{t('search_no_result')}
				</PlentyBody>
				<PlentyBody size="lg" className="font-bold">
					‘{searchTerm}’
				</PlentyBody>
			</div>
			<div className="flex flex-col gap-2">
				<div>{t('search_try')}</div>
				<div className="flex flex-col">
					<div className="flex items-center">
						<div className=" flex px-[4px]">&#8226;</div>
						<PlentyBody size="md">{t('search_double_check')} </PlentyBody>
					</div>
					<div className="flex items-center">
						<div className=" flex px-[4px]">&#8226;</div>
						<PlentyBody size="md">{t('search_again')}</PlentyBody>
					</div>
					<div className="flex items-center">
						<div className=" flex px-[4px]">&#8226;</div>
						<PlentyBody size="md">{t('search_explore_category')}</PlentyBody>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NoResultFound
